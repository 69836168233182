import CTALink from "@components/elements/CTALink"
import GGFXImage from "@components/elements/GGFXImage"
import Slider from "@components/elements/Slider/Slider"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./ServicesGrid.scss"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { parseHTML } from "@lib/utils"
import ArrowRight from "@components/icons/ArrowRight"

const ServiceItem = ({ service, ggfx_results, strapiID, noslider }) => {
  
  const { cta, image, description } = service

  const hasCta = !!cta?.custom_link || !!cta?.menu

  
  const Comp = hasCta ? CTALink : "div"

  return (
    <div className="service-item">
      <Comp cta={cta} className={clsx("img-section", "img-zoom" , !hasCta && "no-cursur")}>
        {/* <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={image?.url} alt={description} /> */}
        <GGFXImage
          ImageSrc={image}
          altText={description}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename={noslider ? "page.image.service_img1" : "page.image.service_img"}
          strapiID={strapiID}
        />
      </Comp>
      <div className={`content-section ${!cta?.cta_label && !hasCta && !description && "no-space"}`}>
        {hasCta ? (
          <CTALink cta={cta} className="title" />
        ) : (
          cta?.cta_label &&<p className="title">{cta?.cta_label}</p>
        )}
        {description &&
        <p className="description">{description}</p>}
      </div>
    </div>
  )
}

const ServicesGrid = ({ module, ggfx_results, strapiID, isgridOnly }) => {
  if (!module) return null

  const { title, services, heading, grid_count, design_title, cta,bg_color } = module
  const { isAboveLargeScreen } = useDeviceMedia()
  const CTA = getCTA(cta, { bg_color })
  return (
    <div className="our-services-wrap section-m grid">
      <Container className="our-services-container">
        {heading && <p className="heading">{heading}</p>}
        {design_title && <div className="design_title">
          {parseHTML(design_title?.data?.design_title)}
          </div>}
        <h2 className="title">{title}</h2>
        {!isAboveLargeScreen && !isgridOnly ?
        <div className="services-section">
          <Slider className="services-slider" size="sm">
            {services?.map((service) => (
              <ServiceItem
                service={service}
                key={service.id}
                ggfx_results={ggfx_results}
                strapiID={strapiID}
              />
            ))}
          </Slider>
        </div> :
         <div className="services-section">
        <div className={`services-grid ${grid_count}`}>
           {services?.map((service) => (
              <ServiceItem
                service={service}
                key={service.id}
                ggfx_results={ggfx_results}
                strapiID={strapiID}
                noslider
              />
            ))}
        </div>
        </div>
        }
        <div className="bt-top">
        {CTA}
        </div>
      </Container>
    </div>
  )
}

export const getCTA = (cta, { bg_color } = {}) => {
  let CTA = null

  const isBlueBg = bg_color === "blue"

  if (cta) {
    if (cta?.custom_link?.startsWith("#")) {
      CTA = (
        <button
          className={clsx(
            "button ",
            isBlueBg ? "button-white-outline" : "button-orange"
          )}
          onClick={() => {
            scrollToID(cta.custom_link.slice(1))
          }}
        >
          <span>{cta.cta_label}</span>
          <ArrowRight color="white" />
        </button>
      )
    } else {
      CTA = (
        <CTALink
          cta={cta}
          className={clsx(
            "button ",
            isBlueBg ? "button-white-outline" : "button-orange"
          )}
        >
          <span>{cta.cta_label}</span>
          <ArrowRight color="white" />
        </CTALink>
      )
    }
  }

  return CTA
}

export default ServicesGrid