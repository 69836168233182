import AreaguideCard from "@components/AreaguideCard/AreaguideCard"
import Pagination from "@components/Pagination/Pagination"
import CustomSelect from "@components/elements/CustomSelect/CustomSelect"
import { Tab, Tabs } from "@components/elements/Tabs/Tabs"
import useAreaguides from "@hooks/dataHooks/useAreaguides"
import useDeviceMedia from "@hooks/useDeviceMedia"
import usePagination from "@hooks/usePagination"
import { capitalize, isEmpty } from "lodash"
import React, {useState} from "react"
import { Container } from "react-bootstrap"
import "./CommunitiesListing.scss"
import "./icons.scss"
import { amenitiesOptions } from "@components/SearchResults/SearchFilters/filterOptions"
import Breach from "../../../static/images/icons/beachfront-properties-new.svg"
import Golf from "../../../static/images/icons/near-golf-course-new.svg"
import Villa from "../../../static/images/icons/villa-community-new.svg"
import { useSessionStorage } from "@hooks/useSessionStorage"

const CommunitiesListing = () => {
  const { isTablet } = useDeviceMedia()
  const [search, setSearch] = useState("")

  const allAreaguides = useAreaguides()

  const [itemsToShow, setItemsToShow] = React.useState(allAreaguides)

  // get all amenities
  const amenities = allAreaguides?.reduce(
    (acc, amenity) => {
      const areaguideAmenity = amenity.amenities?.strapi_json_value
      if (!isEmpty(areaguideAmenity)) {
        areaguideAmenity.forEach((amenityItem) => {
          if (!acc.find((item) => item.value === amenityItem)) {
            acc.push({
              label: capitalize(amenityItem),
              value: amenityItem,
            })
          }
        })
      }
      return acc
    },
    [{ label: "All", value: "all" }]
  )
  // get all amenities

  const [selectedAmenity, setSelectedAmenity] = React.useState(
    amenities?.[0].value
  )
  const [currentPage, setCurrentPage] = useSessionStorage("areaguide_page", 1)
  const paginationProps = usePagination({
    items: itemsToShow,
    itemsPerPage: 24,
    customCurrentPage: currentPage,
    customSetCurrentPage: setCurrentPage,
  })

  // filter teams based on selected category
  React.useEffect(() => {
    if (selectedAmenity === "all") {
      if(search){
        let items = allAreaguides.filter((item, index) =>
        item?.title
          ?.toLowerCase()
          ?.includes(search.toLowerCase())
      )
      setItemsToShow(items)
    
      }else {
        setItemsToShow(allAreaguides)

      }
    } else {
      if(search){
        let filteritems = allAreaguides.filter((areaguide) => {
            const areaguideAmenities = areaguide.amenities?.strapi_json_value
            return areaguideAmenities?.includes(selectedAmenity)
          })

          let items = filteritems.filter((item, index) =>
        item?.title
          ?.toLowerCase()
          ?.includes(search.toLowerCase())
      )
      setItemsToShow(items)
          
      }
      else{
        setItemsToShow(
          allAreaguides.filter((areaguide) => {
            const areaguideAmenities = areaguide.amenities?.strapi_json_value
            return areaguideAmenities?.includes(selectedAmenity)
          })
        )
  
      }
    }
    // paginationProps.setCurrentPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAmenity, allAreaguides, search])
  const handleChange = event => {
    setSearch(event.target.value)
    paginationProps.setCurrentPage(1)
  }

  return (
    <div className="community-listing-wrap listing-wrap">
      <div className="amenities-section-wrap category-section">
        <Container className="amenities-section">
          <div className="max-filter">
          {/* {isTablet ? ( */}
          <div className="search-box-comm">
                    <input
                      className="form-control search"
                      placeholder="Search Communities"
                      onChange={handleChange}
                      value={search}
                    />
                    <span>
                      <i className="icon grey-search-icon" />
                    </span>
                  </div>
            <CustomSelect
              options={amenities}
              placeholder="Select Amenity"
              value={
                amenities.find((item) => item.value === selectedAmenity) ||
                amenities[0]
              }
              onChange={(option) => {
                setSelectedAmenity(option.value)
                paginationProps.setCurrentPage(1)
              }}
            />
            
          {/* ) : (
            <div className="amenities-tabs-section">
              <Tabs
                activeKey={selectedAmenity}
                setActiveKey={setSelectedAmenity}
                className="amenities-tabs"
              >
                {amenities.map((amenity) => {
                  const icon = amenitiesOptions.find(
                    (item) =>
                      item?.label.toLowerCase() ===
                      amenity?.label?.toLowerCase()
                  )

                  return (
                    <Tab
                      eventKey={amenity.value}
                      title={amenity.label}
                      key={amenity.value}
                    >
                      {icon?.icon === "beachfront-properties" ? <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={Breach} alt="icon" /> 
                      : icon?.icon === "near-golf-course" ? <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={Golf} alt="icon" /> 
                      : icon?.icon === "villa-community" ? <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={Villa} alt="icon" /> 
                      :

                      <i className={`icon icon-${icon?.icon}`} />}
                      <span>{amenity.label}</span>
                    </Tab>
                  )
                })}
              </Tabs>
            </div>
          )} */}
          </div>
        </Container>
      </div>
    
      <Container className="community-listing-container">
        <div className="community-amenities-select-section" />
        {paginationProps?.currentItems?.length > 0 ? 
        <div className="community-listing-section">
          {paginationProps?.currentItems?.map((areaguide) => (
            <AreaguideCard key={areaguide.id} areaguide={areaguide} setSelectedAmenity={setSelectedAmenity} selectedAmenity={selectedAmenity}/>
          ))}
        </div>
        : <p>Unfortunately, we do not currently have any Communities that match your search criteria.</p>}
        <Pagination {...paginationProps} />
      </Container>
    </div>
  )
}

export default CommunitiesListing
