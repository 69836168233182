import BannerBG from "@components/Banner/BannerBG"
import BannerListing from "@components/BannerListing/BannerListing"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import CommunitiesListing from "@components/CommunitiesListing/CommunitiesListing"
import Layout from "@components/Layout/Layout"
import loadable from "@loadable/component"
import NewsListing from "@components/NewsListing/NewsListing"
import TeamListing from "@components/TeamListing/TeamListing"
import Seo from "@components/seo"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import "../styles/listing-page.scss"
import DevelopersListing from "@components/DevelopersListing/DevelopersListing"
import ReviewSlider from "@components/ReviewSlider/ReviewSlider"
import ServicesGrid from "@components/ServicesGrid/ServicesGrid"
import BannerMenu from "@components/Banner/BannerMenu"
const VideoAskWidget = loadable(() => import("@components/VideoAsk/VideoAsk"))
const ContactFomModule = loadable(() =>
  import("@components/ContactFomModule/ContactFomModule")
)
const ListingTemplate = ({ data }) => {
  const pageData = data?.strapiPage
  const modules = pageData?.modules
  const banner = pageData?.banner
  const strapiID = pageData?.strapi_id
  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
  }, []);
  return (
    <Layout pageData={pageData}>
      <div className="listing-page-wrap">
        <div className="listing-page-top">
          <BannerBG />
          <BannerMenu color="black" />
          <Breadcrumbs menuData={pageData?.menu} />
          <BannerListing bannerData={banner} />
        </div>
        {/* {pageData?.video_ask_url &&
<VideoAskWidget video_ask_url={pageData?.video_ask_url} />
} */}
        {modules?.map((module) => (
          <React.Fragment key={module.id}>
            {module?.strapi_component === "modules.listing-module" &&
              module.module === "team_listing" && <TeamListing />}
            {module?.strapi_component === "modules.listing-module" &&
              module.module === "news_listing" && <NewsListing />}
            {module?.strapi_component === "modules.listing-module" &&
              module.module === "communities_listing" && <CommunitiesListing />}
            {module?.strapi_component === "modules.listing-module" &&
              module.module === "developer_listing" && <DevelopersListing />}
               {module?.strapi_component === "modules.our-services" && module?.is_grid && (
            <ServicesGrid
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
              isgridOnly
            />
          )}
              {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "reviews_slider" && (
              <ReviewSlider module={module} reviews={pageData?.reviews} />
            )}
             {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "contact_module" && (
              <ContactFomModule module={module} />
            )}
          </React.Fragment>
        ))}
      </div>
    </Layout>
  )
}

export default ListingTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_LISTING_MODULE {
          id
          module
          strapi_component
        }
        ... on STRAPI__COMPONENT_MODULES_OUR_SERVICES {
          ...ourServices
        }
        ... on STRAPI__COMPONENT_MODULES_GLOBAL_MODULE {
          ...globalModule
        }
      }
    }
  }
`
